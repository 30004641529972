<template>
  <div>
    <v-card class="click-to-dial">
      <v-card-title>
        <span>{{$t('t.ClickToDial')}}</span>
        <v-btn
          class="ml-4"
          :disabled="!isDirty"
          fab
          x-small
          color="success"
          @click.stop="save()"
        >
          <v-icon ref="check">{{$icon('i.Checked')}}</v-icon>
        </v-btn>
        <v-btn
          class="ml-2"
          :disabled="!isDirty"
          fab
          x-small
          color="warning"
          @click.stop="load()"
        >
          <v-icon>{{$icon('i.Undo')}}</v-icon>
        </v-btn>
      </v-card-title>
      <v-skeleton-loader
        type="image"
        class="risk"
        v-if="!settings"
      />
      <v-card-text v-else>
        <v-form>
          <v-text-field
            class="protocol"
            v-model="settings.protocol"
            :label="$t('t.Protocol')"
          ></v-text-field>
        </v-form>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {
  comments: {
  },
  mounted () {
    this.load()
  },
  computed: {
    isDirty () {
      return !(JSON.stringify(this.settings) === JSON.stringify(this.initialSettings))
    }
  },
  data () {
    return {
      initialSettings: null,
      settings: null
    }
  },
  methods: {
    async load () {
      this.showConfirmation = false
      this.securityCodeValue = null
      const r = await this.$http().get('/core/v6/settings/click-to-dial-settings')
      this.settings = r?.data
      this.initialSettings = Object.assign({}, r?.data)
    },
    async save () {
      await this.$http().post('/core/v6/settings/click-to-dial-settings', this.settings)
        .then(() => {
          this.$store.dispatch('showSuccessSnackbar', this.$t('t.Snackbar.SaveConfirmation'))
          this.load()
        })
        .catch(e => this.$store.dispatch('showErrorSnackbar', e.response?.data?.message))
    }
  }
}
</script>

<style lang="stylus">
.protocol
  max-width 100px

.ecs-res-grid
  display grid
  grid-template-columns repeat(auto-fill, minmax(15rem, auto))
  column-gap 1em
</style>
